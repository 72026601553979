// import React, { useEffect } from 'react';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import SearchIcon from '@mui/icons-material/Search';
// import Avatar from '@mui/material/Avatar';
// import Badge from '@mui/material/Badge';
// import PhoneIcon from '@mui/icons-material/Phone';
// import Box from '@mui/material/Box';
// import SettingsIcon from '@mui/icons-material/Settings';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import Button from '@mui/material/Button';
// import axios from 'axios';
// import Swal from 'sweetalert2';



// // Helper function to get today's date in the desired format
// const getFormattedDate = () => {
//   const today = new Date();
//   const options = { year: 'numeric', month: 'long', day: 'numeric' };
//   return today.toLocaleDateString('en-US', options);
// };

// const CustomAppBar = ({ onUpdateAbsence = () => {} }) => {
//   const currentDate = getFormattedDate();

//   // // استدعاء Custom Hook لتشغيل الوظيفة
//   // useDailyAPI();

//   const handleAttendanceUpdate = () => {
//     const now = new Date();
//     const hour = now.getHours();

//     if (hour <= 9) {
//       Swal.fire({
//         icon: 'error',
//         title: 'تنبيه',
//         text: 'تحديث الحضور يجب أن يكون بعد الساعة 10 صباحًا',
//         confirmButtonText: 'حسناً',
//       });
//       return;
//     }

//     axios
//       .post('https://backendforerpsystem.zbooma.com/update_absence.php')
//       .then((response) => {
//         if (response.data.success) {
//           const absentEmployees = response.data.absentEmployees; // Updated absence data
//           console.log('Absent Employees:', absentEmployees); // عرض قائمة الموظفين الغائبين

//           onUpdateAbsence(absentEmployees); // Pass to parent
//           Swal.fire({
//             icon: 'success',
//             title: 'تم تحديث الحضور بنجاح',
//             text: `تم اعتبار الموظفين التالين غائبين: ${absentEmployees.join(', ')}`,
//             confirmButtonText: 'حسناً',
//           });
//         } else {
//           Swal.fire({
//             icon: 'error',
//             title: 'خطأ',
//             text: 'فشل تحديث الحضور: ' + response.data.message,
//             confirmButtonText: 'حسناً',
//           });
//         }
//       })
//       .catch((error) => {
//         console.error('Error updating attendance:', error);
//         Swal.fire({
//           icon: 'error',
//           title: 'خطأ',
//           text: 'حدث خطأ أثناء الاتصال بالسيرفر.',
//           confirmButtonText: 'حسناً',
//         });
//       });
//   };

//   return (
//     <AppBar position="static" sx={{ backgroundColor: '#2C2C2C' }}>
//       <Toolbar>
//         {/* Dynamic Date */}
//         <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
//           <Typography
//             sx={{
//               backgroundColor: '#1976D2',
//               padding: '4px 10px',
//               borderRadius: '20px',
//               color: 'white',
//               mr: 1,
//             }}
//           >
//             {currentDate} {/* Display the current date dynamically */}
//           </Typography>
//         </Box>

//         {/* Telephone Calls */}
//         <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
//           <IconButton color="inherit">
//             <PhoneIcon style={{ color: 'orange' }} />
//           </IconButton>
//           <Typography sx={{ color: 'white', fontWeight: 'bold' }}>Telephone</Typography>
//           <Typography sx={{ color: 'white', ml: 1 }}>240 Calls</Typography>
//         </Box>

//         {/* Search Bar */}
//         <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
//           <Box
//             sx={{
//               backgroundColor: '#444',
//               padding: '8px 20px',
//               borderRadius: '30px',
//               display: 'flex',
//               alignItems: 'center',
//               width: '400px',
//             }}
//           >
//             <SearchIcon style={{ color: 'white', marginRight: '10px' }} />
//             <Typography sx={{ color: 'white' }}>Search</Typography>
//           </Box>
//         </Box>

//         {/* Update Attendance Button */}
//         <Button
//           variant="contained"
//           color="primary"
//           sx={{ mr: 2, backgroundColor: '#6fd943', fontFamily: 'Cairo, sans-serif' }}
//           onClick={handleAttendanceUpdate}
//         >
//           تحديث الحضور
//         </Button>

//         {/* Icons and User Profile */}
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           {/* Notification Icon with Badge */}
//           <Badge color="error" variant="dot" sx={{ mr: 2 }}>
//             <NotificationsIcon style={{ color: 'white' }} />
//           </Badge>

//           {/* Settings Icon */}
//           <IconButton color="inherit" sx={{ mr: 2 }}>
//             <SettingsIcon style={{ color: 'white' }} />
//           </IconButton>

//           {/* User Avatar */}
//           <Avatar alt="User Profile" src="/path/to/profile-pic.jpg" />
//         </Box>
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default CustomAppBar;

import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import PhoneIcon from '@mui/icons-material/Phone';
import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Button from '@mui/material/Button';
import axios from 'axios';
import Swal from 'sweetalert2';

// Helper function to get today's date in the desired format
const getFormattedDate = () => {
  const today = new Date();
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return today.toLocaleDateString('en-US', options);
};

const CustomAppBar = ({ onUpdateAbsence = () => {} }) => {
  const currentDate = getFormattedDate();
  const handleAttendanceUpdate = () => {
    const now = new Date();
    const hour = now.getHours();
  
    if (hour <= 9) {
      Swal.fire({
        icon: 'error',
        title: 'تنبيه',
        text: "تحديث الحضور يجب ان يكون بعد ال 10 صباحا",
        confirmButtonText: 'حسناً',
      });
      return;
    }
    
  axios
  .post('https://backendforerpsystem.zbooma.com/update_absence.php')
  .then((response) => {
    console.log('Server Response:', response.data); // Debugging: البيانات المستلمة

    if (response.data.success) {
      const absentEmployees = response.data.absentEmployees; // Updated absence data (كائن)
      console.log('Absent Employees:', absentEmployees); // Debugging: عرض قائمة الموظفين الغائبين

      // تحويل الكائن إلى مصفوفة
      const absentEmployeesArray = Object.values(absentEmployees); // ["user1", "user2", ...]

      onUpdateAbsence(absentEmployeesArray); // Pass to parent

      if (absentEmployeesArray.length > 0) {
        Swal.fire({
          icon: 'success',
          title: 'تم تحديث الحضور بنجاح',
          text: `تم اعتبار الموظفين التالين غائبين: ${absentEmployeesArray.join(', ')}`,
          confirmButtonText: 'حسناً',
        });
      } else {
        Swal.fire({
          icon: 'info',
          title: 'لا يوجد موظفين غائبين',
          text: 'لم يتم العثور على موظفين غائبين.',
          confirmButtonText: 'حسناً',
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: 'فشل تحديث الحضور: ' + response.data.message,
        confirmButtonText: 'حسناً',
      });
    }
  })
  .catch((error) => {
    console.error('Error updating attendance:', error); // Debugging: الأخطاء
    Swal.fire({
      icon: 'error',
      title: 'خطأ',
      text: 'حدث خطأ أثناء الاتصال بالسيرفر.',
      confirmButtonText: 'حسناً',
    });
  });
  };

  useEffect(() => {
    const scheduleAttendanceUpdate = () => {
      const now = new Date();
      const nextUpdate = new Date();
  
      // Set the next update to 10:00 AM
      nextUpdate.setHours(10, 0 , 0, 0);

      // If the current time is past 2:30 PM, schedule for the next day
      if (now > nextUpdate) {
        nextUpdate.setDate(nextUpdate.getDate() + 1);
      }
  
      // Calculate the delay in milliseconds
      const delay = nextUpdate - now;
      
      console.log('Current Time (now):', now);
      console.log('Next Update Time (nextUpdate):', nextUpdate);
      console.log('Delay (ms):', delay);
  
      // Schedule the update
      const timerId = setTimeout(() => {
        const day = nextUpdate.getDay(); // 0 = Sunday, 1 = Monday, ..., 5 = Friday
  
        if (day !== 5) {
          handleAttendanceUpdate();
        }
  
        // Reschedule for the next day
        scheduleAttendanceUpdate();
      }, delay);
  
      return () => clearTimeout(timerId); // Cleanup on component unmount
    };
  
    scheduleAttendanceUpdate();
  }, []);
  
  

  return (
    <AppBar position="static" sx={{ backgroundColor: '#2C2C2C' }}>
      <Toolbar>
        {/* Dynamic Date */}
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <Typography
            sx={{ backgroundColor: '#1976D2', padding: '4px 10px', borderRadius: '20px', color: 'white', mr: 1 }}
          >
            {currentDate} {/* Display the current date dynamically */}
          </Typography>
        </Box>

        {/* Telephone Calls */}
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <IconButton color="inherit">
            <PhoneIcon style={{ color: 'orange' }} />
          </IconButton>
          <Typography sx={{ color: 'white', fontWeight: 'bold' }}>Telephone</Typography>
          <Typography sx={{ color: 'white', ml: 1 }}>240 Calls</Typography>
        </Box>

        {/* Search Bar */}
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              backgroundColor: '#444',
              padding: '8px 20px',
              borderRadius: '30px',
              display: 'flex',
              alignItems: 'center',
              width: '400px',
            }}
          >
            <SearchIcon style={{ color: 'white', marginRight: '10px' }} />
            <Typography sx={{ color: 'white' }}>Search</Typography>
          </Box>
        </Box>

        {/* Update Attendance Button */}
        <Button
          variant="contained"
          color="primary"
          sx={{ mr: 2, backgroundColor: '#6fd943', fontFamily: 'Cairo, sans-serif' }}
          onClick={handleAttendanceUpdate}
        >
          تحديث الحضور
        </Button>

        {/* Icons and User Profile */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Notification Icon with Badge */}
          <Badge color="error" variant="dot" sx={{ mr: 2 }}>
            <NotificationsIcon style={{ color: 'white' }} />
          </Badge>

          {/* Settings Icon */}
          <IconButton color="inherit" sx={{ mr: 2 }}>
            <SettingsIcon style={{ color: 'white' }} />
          </IconButton>

          {/* User Avatar */}
          <Avatar alt="User Profile" src="/path/to/profile-pic.jpg" />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;

