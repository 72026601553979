import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  Avatar,
  Badge,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Button
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import { styled } from '@mui/system';
import Chat from '../ChatComponent/ChatComponent';
import io from 'socket.io-client';

const socket = io('https://my-chat-app-zn13.onrender.com'); // Replace with your WebSocket server URL

const EmployeeCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: theme.spacing(2),
  textAlign: 'center',
  position: 'relative',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  borderRadius: theme.spacing(2),
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const ChatButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const EmployeeLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
}));

const EmployeesForUsers = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1); // Added for pagination
  const [hasMore, setHasMore] = useState(true); // Added to track if there are more employees to load
  const [openChat, setOpenChat] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [senderId, setSenderId] = useState(null);
  const [newMessages, setNewMessages] = useState({});
  const location = useLocation();

  useEffect(() => {

    const username = location.state?.username; // Access the username from the state with optional chaining

    const fetchEmployees = (page) => {
      axios.get(`https://backendforerpsystem.zbooma.com/getemployees.php?page=${page}`)
        .then(response => {
          if (response.data.status === 'success') {
            const loggedInUser = response.data.data.find(emp => emp.username === username);
            if (loggedInUser) {
              setSenderId(loggedInUser.employee_id);
              const filteredEmployees = response.data.data.filter(emp => emp.employee_id !== loggedInUser.employee_id);
              setEmployees(prevEmployees => [...prevEmployees, ...filteredEmployees]);
              setHasMore(filteredEmployees.length > 0);

              socket.emit('user_online', loggedInUser.employee_id);

              // Fetch notification statuses
              axios.get(`https://backendforerpsystem.zbooma.com/get_notifications.php?receiver_id=${loggedInUser.employee_id}`)
                .then(notificationsResponse => {
                  if (notificationsResponse.data.success) {
                    const notifications = notificationsResponse.data.notifications.reduce((acc, notification) => {
                      acc[notification.sender_id] = notification.is_read === 0;
                      return acc;
                    }, {});
                    setNewMessages(notifications);
                  }
                });
            } else {
              console.error('Logged-in user not found');
            }
          }
        })
        .catch(error => {
          console.error('Error fetching employee data:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchEmployees(page);

    socket.on('user_status_change', (updatedEmployee) => {
      setEmployees(prevEmployees =>
        prevEmployees.map(emp =>
          emp.employee_id == updatedEmployee.employee_id
            ? { ...emp, online_status: updatedEmployee.online_status }
            : emp
        )
      );
    });

    socket.on('new_message', (message) => {
      if (message.receiver_id == senderId) {
        setNewMessages(prevMessages => ({
          ...prevMessages,
          [message.sender_id]: true
        }));
      }
    });

    return () => {
      socket.emit('user_offline', senderId);
      socket.off('user_status_change');
      socket.off('new_message');
    };
  }, [location.search, senderId, page]);

  const handleChatOpen = (employee) => {
    setSelectedEmployee(employee);
    setOpenChat(true);
    axios.post('https://backendforerpsystem.zbooma.com/update_notification_status.php', {
      sender_id: employee.employee_id,
      receiver_id: senderId
    }).then(() => {
      setNewMessages(prevMessages => ({
        ...prevMessages,
        [employee.employee_id]: false
      }));
    }).catch(error => {
      console.error('Error updating notification status:', error);
    });
  };

  const handleChatClose = () => {
    setOpenChat(false);
    setSelectedEmployee(null);
  };

  const loadMoreEmployees = () => {
    if (hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  if (loading && page === 1) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center">
      <Grid container spacing={3} justifyContent="center">
        {employees.map(employee => {
          const imageUrl = employee.photo
            ? `https://backendforerpsystem.zbooma.com/${employee.photo}`
            : 'https://via.placeholder.com/80';

          return (
            <Grid item xs={12} sm={6} md={4} key={employee.employee_id}>
              <EmployeeCard>
                <EmployeeLink>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <Box
                        sx={{
                          backgroundColor: employee.online_status === 1 ? 'green' : 'red',
                          borderRadius: '50%',
                          width: 12,
                          height: 12,
                        }}
                      />
                    }
                  >
                    <Avatar
                      alt={employee.name}
                      src={imageUrl}
                      sx={{ width: 80, height: 80, margin: 'auto', marginTop: 2 }}
                    />
                  </Badge>
                </EmployeeLink>
                <Badge
                  badgeContent={newMessages[employee.employee_id] ? 1 : 0}
                  color="secondary"
                >
                  <ChatButton onClick={() => handleChatOpen(employee)}>
                    <ChatIcon />
                  </ChatButton>
                </Badge>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {employee.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {employee.role}
                  </Typography>
                </CardContent>
              </EmployeeCard>
            </Grid>
          );
        })}
      </Grid>


      <Dialog open={openChat} onClose={handleChatClose} fullWidth maxWidth="md">
        <DialogTitle>Chat with {selectedEmployee?.name}</DialogTitle>
        <DialogContent>
          {selectedEmployee && <Chat senderId={senderId} receiverId={selectedEmployee.employee_id} />}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EmployeesForUsers;
