import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Typography,
  Box,
  Avatar,
  Grid,
  Paper,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';

import annotationPlugin from 'chartjs-plugin-annotation';
import { Doughnut, Line, Bar } from 'react-chartjs-2';
import { Description, Visibility as VisibilityIcon } from '@mui/icons-material';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
} from 'chart.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';

ChartJS.register(ArcElement, Tooltip, Legend, LineElement, CategoryScale, LinearScale, BarElement, PointElement);

// Create a dark theme using Material-UI's createTheme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#BBBBBB',
    },
    primary: {
      main: '#6fd943',
    },
    error: {
      main: '#f44336',
    },
  },
});
ChartJS.register(annotationPlugin);

const Profile = () => {


  // Automatically open the dialog when the component mounts
  // useEffect(() => {
  //   setOpenDialog(true); // Set the dialog to open automatically
  // }, []);

    // Handle dialog close

    const location = useLocation(); // Hook to get the location object
    const username = location.state?.username; // Directly access the username from the state
  const [employee, setEmployee] = useState(null);
  const [completedTasksCount, setCompletedTasksCount] = useState(0);
  const [totalTasksCount, setTotalTasksCount] = useState(0);
  const [monthlyTaskData, setMonthlyTaskData] = useState([]);
  const [countdown, setCountdown] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [currentCertificate, setCurrentCertificate] = useState(null);
  const [deductions, setDeductions] = useState([]);
  const [technicalSkills, setTechnicalSkills] = useState([]);
  const [disciplineSkills, setDisciplineSkills] = useState([]);
  const [hierarchyData, setHierarchyData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [leaves, setLeaves] = useState([]); // State for leave requests
  const [permissions, setPermissions] = useState([]); // State for permissions
  const [isEmployeeLoaded, setIsEmployeeLoaded] = useState(false); // New state to track employee data loading
  const orderedRoles = ['Junior', 'Mid-Level', 'Senior', 'Team Leader', 'Head'];
  const daysInArabic = [
    'الأحد',    // Sunday (0)
    'الإثنين',  // Monday (1)
    'الثلاثاء',  // Tuesday (2)
    'الأربعاء',  // Wednesday (3)
    'الخميس',    // Thursday (4)
    'الجمعة',    // Friday (5)
    'السبت',      // Saturday (6)
  ];

  // Function to check if the file can be previewed
const canPreviewFile = (filename) => {
  return filename.endsWith('.pdf') || filename.endsWith('.docx') || filename.endsWith('.ppt') || filename.endsWith('.pptx');
};

// Function to handle opening the certificate preview dialog
const handleOpenDialog = (certificate) => {
  setCurrentCertificate(certificate);
  setOpenDialog(true);
};

// Function to handle closing the certificate preview dialog
const handleCloseDialog = () => {
  setOpenDialog(false);
  setCurrentCertificate(null);
};

const totalDeductionsSum = deductions.reduce((acc, curr) => acc + parseFloat(curr.deduction), 0);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('https://backendforerpsystem.zbooma.com/getemployees.php');
        const result = await response.json();
        if (result.status === 'success') {
          const user = result.data.find(emp => emp.username === username);
          if (user) {
            await fetchEmployeeDetails(user.employee_id);
            await fetchAllTasks(user.department2, username);
            startCountdown(user.joining_date);
            await fetchHierarchy(user.department2);
            await fetchSkills(user.employee_id);
            await fetchLeaves(username); // Fetch leave requests
            await fetchPermissions(username); // Fetch permissions
          }
        } else {
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    const fetchEmployeeDetails = async (employeeId) => {
      try {
        const response = await fetch(`https://backendforerpsystem.zbooma.com/getemployee.php?id=${employeeId}`);
        const result = await response.json();
        if (result.status === 'success') {
          const employeeData = result.data;
          employeeData.certificates = JSON.parse(employeeData.certificates || '[]');

          // Get the blocked day name (convert the number to Arabic string)
          const blockedDayName = daysInArabic[Number(employeeData.blocked_day)] || "لم يتم تحديد اليوم"; // Default to 'N/A' if invalid

          // Add the blocked day name to the employee data
          employeeData.blocked_day_name = blockedDayName;

          setEmployee(employeeData);
          setIsEmployeeLoaded(true); // Set employee data as loaded after fetching employee details
        } else {
        }
      } catch (error) {
      }
    };
    
    const fetchAllTasks = async (department, username) => {
      try {
        const response = await fetch('https://backendforerpsystem.zbooma.com/getalltasksforallemloyees.php');
        const result = await response.json();
        if (result.success) {
          const tasksInDepartment = result.tasks.filter(task =>
            task.task_departments.split(',').map(dep => dep.trim()).includes(department)
          );
          const completedTasks = tasksInDepartment.filter(task => task.username === username && task.status === 'done');
          setCompletedTasksCount(completedTasks.length);
          setTotalTasksCount(tasksInDepartment.length);

          const tasksByMonth = new Array(12).fill(0);
          tasksInDepartment.forEach(task => {
            const taskMonth = new Date(task.task_due_date).getMonth();
            tasksByMonth[taskMonth] += 1;
          });
          setMonthlyTaskData(tasksByMonth);
        } else {
        }
      } catch (error) {
      }
    };

    const fetchLeaves = async (username) => {
      try {
        const response = await fetch(`https://backendforerpsystem.zbooma.com/leaveRequest.php?username=${username}`);
        const result = await response.json();
        if (result && Array.isArray(result)) {
          setLeaves(result); // Set leaves if result is an array
        } else {
          setLeaves([]); // Set an empty array if the response format is incorrect
        }
      } catch (error) {
        setLeaves([]); // Handle errors by setting leaves to an empty array
      }
    };
    
    const fetchPermissions = async (username) => {
      try {
        
        const response = await fetch(`https://backendforerpsystem.zbooma.com/getpermissions.php/?username=${username}`);
        // https://backendforerpsystem.zbooma.com/getpermissions.php/?username=${username}
        // const response = await fetch(`https://backendforerpsystem.zbooma.php/getpermissions.php/?username=${username}`);
        const result = await response.json();
        if (result && Array.isArray(result)) {
          setPermissions(result);
        } else {
          setPermissions([]); // Ensure permissions is an array even if the response is not as expected
        }
      } catch (error) {
        console.error("Failed to fetch permissions:", error);
        setPermissions([]); // Set permissions to an empty array in case of error
      }
    };
    

    const fetchHierarchy = async (department) => {
      try {
        const response = await fetch(`https://backendforerpsystem.zbooma.com/gethierarchy.php?department=${department}`);
        const result = await response.json();
        if (result.status === 'success') {
          setHierarchyData(result.data);
        } else {
        }
      } catch (error) {
      }
    };

    const fetchSkills = async (employeeId) => {
      try {
        const skillsResponse = await fetch(`https://backendforerpsystem.zbooma.com/getSkills.php?employeeId=${employeeId}`);
        const skillsResult = await skillsResponse.json();
        const disciplineSkillsResponse = await fetch(
          `https://backendforerpsystem.zbooma.com/getDisciplineSkills.php?employeeId=${employeeId}`
        );
        const disciplineSkillsResult = await disciplineSkillsResponse.json();

        setTechnicalSkills(skillsResult.skills);
        setDisciplineSkills(disciplineSkillsResult.skills);
      } catch (err) {
      }
    };

    const startCountdown = (joiningDate) => {
      const nextAnniversary = new Date(joiningDate);
      nextAnniversary.setFullYear(nextAnniversary.getFullYear() + 1);

      const interval = setInterval(() => {
        const now = new Date();
        const timeLeft = nextAnniversary - now;

        if (timeLeft < 0) {
          nextAnniversary.setFullYear(nextAnniversary.getFullYear() + 1);
        }

        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

        setCountdown(`${days} يوم ${hours} ساعة ${minutes} دقيقة ${seconds} ثانية`);

        if (timeLeft < 0) {
          clearInterval(interval);
          setCountdown('تم الوصول إلى الزيادة الخاصة بك!');
        }
      }, 1000);
    };

    fetchEmployeeData();
  }, [username]);

  useEffect(() => {
    if (isEmployeeLoaded && employee) {
      fetchAttendanceRecords(username); // Ensure attendance records are fetched only after employee data is loaded
    }
  }, [isEmployeeLoaded, employee]);

  const fetchAttendanceRecords = async (username) => {
    try {
      const response = await fetch('https://backendforerpsystem.zbooma.com/get_attendance_month.php');
      const result = await response.json();
      if (result.success) {
        const userAttendance = result.records.filter(record => record.username === username);
        
        // Log all attendance records for the user
        
        calculateDeductions(userAttendance);
      } else {
      }
    } catch (error) {
    }
  };

  const calculateDeductions = (records) => {
    if (!employee) return;
  
    const calculatedDeductions = [];
  
    // Define the thresholds for deductions using UTC time for consistent comparison
    const generalThreshold1 = new Date('1970-01-01T09:25:00Z'); // General threshold for quarter day deduction
    const generalThreshold2 = new Date('1970-01-01T09:30:00Z'); // General threshold for half day deduction
  
    const nourhanThreshold1 = new Date('1970-01-01T09:30:00Z'); // Special threshold for quarter day deduction for nourhan
    const nourhanThreshold2 = new Date('1970-01-01T09:35:00Z'); // Special threshold for half day deduction for nourhan
  
    const fullDayDeductionAmount = (employee.salary / 30).toFixed(2); // Calculate full-day deduction
  
    // Iterate through the records to determine deductions
    records.forEach((record) => {
      let threshold1, threshold2;
  
      // Set custom thresholds for `nourhan`
      if (record.username === 'nourhan') {
        threshold1 = nourhanThreshold1;
        threshold2 = nourhanThreshold2;
      } else {
        threshold1 = generalThreshold1;
        threshold2 = generalThreshold2;
      }
  
      // If the employee is marked as absent (`notexist` = 1)
      if (record.notexist === 1) {
        calculatedDeductions.push({
          date: record.date,
          time: record.time || 'غير مسجل', // Display 'غير مسجل' if no time is recorded
          deduction: fullDayDeductionAmount,
          reason: "خصم يوم كامل - غائب", // Full-day deduction for absence
        });
      } else {
        // Parse the attendance time using UTC
        const attendanceTime = new Date(`1970-01-01T${record.time}Z`);
  
        // Log attendance time and thresholds for debugging  
        if (attendanceTime > threshold1 && attendanceTime <= threshold2) {
          // Deduct quarter day if attended between 09:25 (or 09:30 for nourhan) and 09:30 (or 09:35 for nourhan)
          const quarterDayDeduction = (employee.salary / 30 / 4).toFixed(2);
          calculatedDeductions.push({
            date: record.date,
            time: record.time,
            deduction: quarterDayDeduction,
            reason: "مخصوم ربع يوم - تأخير",
          });
        } else if (attendanceTime > threshold2) {
          // Deduct half day if attended after 09:30 (or 09:35 for nourhan)
          const halfDayDeduction = (employee.salary / 30 / 2).toFixed(2);
          calculatedDeductions.push({
            date: record.date,
            time: record.time,
            deduction: halfDayDeduction,
            reason: "مخصوم نصف يوم - تأخير",
          });
        }
      }
    });
  
    setDeductions(calculatedDeductions);
  };
  
  
  

  // Discipline threshold (adjustable)
  const disciplineThreshold = 50;

  // Get the dates and amounts for the deductions
  const deductionDates = deductions.map((deduction) => deduction.date);
  const deductionAmounts = deductions.map((deduction) => parseFloat(deduction.deduction));

  // Determine the employee's discipline level based on the total deductions
  const totalDeductions = deductionAmounts.reduce((acc, curr) => acc + curr, 0);
  let disciplineLevel = '';
  let disciplineColor = '';

  if (totalDeductions < disciplineThreshold) {
    disciplineLevel = 'منضبط - مرتفع';
    disciplineColor = '#4caf50';  // Green
  } else {
    disciplineLevel = 'غير منضبط - منخفض';
    disciplineColor = '#f44336';  // Red
  }

  // Chart data for deductions over time
  const deductionsChartData = {
    labels: deductionDates,
    datasets: [
      {
        label: 'الخصومات',
        data: deductionAmounts,
        borderColor: disciplineColor,
        backgroundColor: 'rgba(244, 67, 54, 0.2)',  // Semi-transparent fill for visual appeal
        fill: true,
      },
    ],
  };

  // Chart options, including a horizontal threshold line
  const deductionsChartOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: '#FFFFFF',
          font: {
            family: 'Cairo, sans-serif',
          },
        },
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: disciplineThreshold, // Set the threshold line at the discipline threshold
            yMax: disciplineThreshold,
            borderColor: '#ff9800',
            borderWidth: 2,
            label: {
              content: 'الحد - منخفض',
              enabled: true,
              position: 'start',
              color: '#ff9800',
              font: {
                family: 'Cairo, sans-serif',
                size: 14,
              },
            },
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#FFFFFF',
        },
      },
      y: {
        ticks: {
          color: '#FFFFFF',
        },
      },
    },
    maintainAspectRatio: false,
    animation: {
      duration: 1500, // Reduced animation duration
      easing: 'easeInOutQuad',
      loop: false, // Disable continuous looping
    },
  };

  if (isLoading) {
    return (
      <Dialog open={true} fullWidth maxWidth="sm">
        <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 4 }}>
          <CircularProgress size={80} />
          <Typography variant="h6" sx={{ mt: 2, fontFamily: 'Cairo, sans-serif' }}>
            جاري تحميل البيانات...
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }

  if (!employee) {
    return <div>Failed to load employee data</div>;
  }
  const leaveChartData = {
    labels: ['مقبول', 'مرفوض'],
    datasets: [
      {
        label: 'الإجازات',
        data: [
          leaves.filter((leave) => leave.is_accepted === 1).length,
          leaves.filter((leave) => leave.is_accepted === 0).length,
        ],
        backgroundColor: ['#4caf50', '#f44336'],
      },
    ],
  };
  
  const permissionChartData = {
    labels: ['مقبول', 'مرفوض'],
    datasets: [
      {
        label: 'الإذن',
        data: [
          permissions.filter((permission) => permission.is_accepted === 1).length,
          permissions.filter((permission) => permission.is_accepted === 0).length,
        ],
        backgroundColor: ['#4caf50', '#f44336'],
      },
    ],
  };

  const cardStyle = {
    padding: '24px',
    margin: '16px 0',
    borderRadius: '20px', // More rounded corners for a modern look
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
    backdropFilter: 'blur(10px)', // Frosted glass effect
    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Transparent background
    border: '1px solid rgba(255, 255, 255, 0.18)', // Soft border for the glass effect
    direction: 'rtl',
    fontFamily: 'Cairo, sans-serif',
    color: darkTheme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    flexGrow: 1,
    transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth hover effect
  };
  
  // Add hover effect
  const hoverEffect = {
    ':hover': {
      transform: 'translateY(-10px)', // Slight lift on hover
      boxShadow: '0 12px 48px rgba(0, 0, 0, 0.2)', // More prominent shadow on hover
    },
  };


  return (
    <ThemeProvider theme={darkTheme}>
      <Box p={3} sx={{ direction: 'rtl' }}>
        <Typography variant="h4" gutterBottom style={{ fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>
          الملف الشخصي
        </Typography>

        {/* Grid for Skills and Charts */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Paper style={{ ...cardStyle }}>
              <Typography
                variant="h6"
                style={{ fontFamily: 'Cairo, sans-serif', marginBottom: '16px', color: '#FFFFFF' }}
              >
                المهارات التقنية
              </Typography>
              <Box sx={{ width: '100%', height: '300px' }}>
                <Bar
                  data={{
                    labels: technicalSkills.map(skill => skill.skill_name),
                    datasets: [
                      {
                        label: 'المهارات التقنية',
                        data: technicalSkills.map(skill => skill.percentage),
                        backgroundColor: '#4caf50',
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        labels: {
                          color: '#FFFFFF',
                        },
                      },
                    },
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        ticks: {
                          color: '#FFFFFF',
                        },
                      },
                      y: {
                        ticks: {
                          color: '#FFFFFF',
                        },
                      },
                    },
                    animation: {
                      duration: 2500,
                      easing: 'easeInOutCubic',
                      loop: false,
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper style={{ ...cardStyle }}>
              <Typography
                variant="h6"
                style={{ fontFamily: 'Cairo, sans-serif', marginBottom: '16px', color: '#FFFFFF' }}
              >
                المهارات الشخصية والانضباطية
              </Typography>
              <Box sx={{ width: '100%', height: '300px' }}>
                <Bar
                  data={{
                    labels: disciplineSkills.map(skill => skill.skill_name),
                    datasets: [
                      {
                        label: 'المهارات الشخصية والانضباطية',
                        data: disciplineSkills.map(skill => skill.percentage),
                        backgroundColor: '#f44336',
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        labels: {
                          color: '#FFFFFF',
                        },
                      },
                    },
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        ticks: {
                          color: '#FFFFFF',
                        },
                      },
                      y: {
                        ticks: {
                          color: '#FFFFFF',
                        },
                      },
                    },
                    animation: {
                      duration: 2500,
                      easing: 'easeInOutCubic',
                      loop: false,
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper style={{ ...cardStyle }}>
              <Typography
                variant="h6"
                style={{ fontFamily: 'Cairo, sans-serif', marginBottom: '16px', color: '#FFFFFF' }}
              >
                نسبة المهام المنفذة
              </Typography>
              <Box sx={{ width: '100%', height: '300px' }}>
                <Doughnut
                  data={{
                    labels: ['منجز', 'غير مكتمل'],
                    datasets: [
                      {
                        data: [completedTasksCount, totalTasksCount - completedTasksCount],
                        backgroundColor: ['#4caf50', '#f44336'],
                        hoverBackgroundColor: ['#66bb6a', '#e57373'],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        position: 'bottom',
                        labels: {
                          font: {
                            family: 'Cairo, sans-serif',
                            color: darkTheme.palette.text.primary,
                          },
                        },
                      },
                    },
                    maintainAspectRatio: false,
                    animation: {
                      duration: 2500,
                      easing: 'easeInOutBounce',
                      loop: false,
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper style={{ ...cardStyle }}>
              <Typography
                variant="h6"
                style={{ fontFamily: 'Cairo, sans-serif', marginBottom: '16px', color: '#FFFFFF' }}
              >
                التقييم السنوي
              </Typography>
              <Box sx={{ width: '100%', height: '300px' }}>
                <Line
                  data={{
                    labels: [
                      'يناير',
                      'فبراير',
                      'مارس',
                      'أبريل',
                      'مايو',
                      'يونيو',
                      'يوليو',
                      'أغسطس',
                      'سبتمبر',
                      'أكتوبر',
                      'نوفمبر',
                      'ديسمبر'
                    ],
                    datasets: [
                      {
                        label: 'عدد المهام المنجزة',
                        data: monthlyTaskData,
                        fill: false,
                        borderColor: '#42a5f5',
                        tension: 0.1,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        position: 'bottom',
                        labels: {
                          font: {
                            family: 'Cairo, sans-serif',
                            color: darkTheme.palette.text.primary,
                          },
                        },
                      },
                    },
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        title: {
                          display: true,
                          text: 'الشهور',
                          font: {
                            family: 'Cairo, sans-serif',
                            size: 14,
                            color: darkTheme.palette.text.primary,
                          },
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: 'عدد المهام',
                          font: {
                            family: 'Cairo, sans-serif',
                            size: 14,
                            color: darkTheme.palette.text.primary,
                          },
                        },
                        beginAtZero: true,
                        ticks: {
                          stepSize: 2,
                        },
                      },
                    },
                    animation: {
                      duration: 2500,
                      easing: 'easeInOutQuad',
                      loop: false,
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>

        {/* الترقيات والزيادات الخاصة بك and الخصومات in the same row */}
        <Grid container spacing={3} sx={{ marginTop: '16px' }}>
          {/* الترقيات والزيادات الخاصة بك */}
          <Grid item xs={12} md={6}>
            <Paper style={cardStyle}>
              <Typography variant="h6" style={{ fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>
                الترقيات والزيادات الخاصة بك
              </Typography>
              <Box sx={{ width: '100%', height: '300px' }}>
                <Bar
                  data={{
                    labels: orderedRoles,
                    datasets: [
                      {
                        label: 'الترقيات',
                        data: orderedRoles.map((role) => (role === employee.role ? 1 : 0.5)),
                        backgroundColor: orderedRoles.map((role) =>
                          role === employee.role ? '#f44336' : '#4caf50'
                        ),
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      annotation: {
                        annotations: {
                          flag: {
                            type: 'label',
                            xValue: orderedRoles.indexOf(employee.role),
                            yValue: 1.070,
                            backgroundColor: 'transparent',
                            content: ['🏁'],
                            position: 'top',
                            font: {
                              size: 30,
                            },
                            textAlign: 'center',
                          },
                        },
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          color: '#FFFFFF',
                        },
                      },
                      y: {
                        display: false,
                      },
                    },
                    animation: {
                      duration: 2500,
                      easing: 'easeInOutCubic',
                      loop: false,
                    },
                  }}
                />
              </Box>
              <Typography
                variant="body1"
                style={{
                  marginTop: '16px',
                  color: darkTheme.palette.primary.main,
                  fontFamily: 'Cairo, sans-serif',
                  border: '1px solid #6fd943',
                  padding: '16px',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                الزيادة الخاصة بك بعد: {countdown}
              </Typography>
            </Paper>
          </Grid>

          {/* الخصومات */}
          <Grid item xs={12} md={6}>
  <Paper style={cardStyle}>
    <Typography variant="h6" style={{ fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>
      الخصومات ومستوى الانضباط
    </Typography>
    <Typography
      variant="body1"
      style={{
        marginTop: '8px',
        color: disciplineColor,
        fontFamily: 'Cairo, sans-serif',
        fontWeight: 'bold',
        textAlign: 'center',
      }}
    >
      {disciplineLevel}
    </Typography>
    <Box sx={{ width: '100%', height: '300px' }}>
      <Line data={deductionsChartData} options={deductionsChartOptions} />
    </Box>

    {/* Render Deductions List */}
{deductions.length > 0 && (
  <Box mt={2} p={2} sx={{ maxHeight: '200px', overflowY: 'auto', backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: '8px' }}>
    <Typography variant="h6" style={{ fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>
      تفاصيل الخصومات
    </Typography>
    {deductions.map((deduction, index) => (
      <Box key={index} mb={2}>
        <Typography
          variant="body2"
          style={{
            fontFamily: 'Cairo, sans-serif',
            color: darkTheme.palette.text.primary,
            marginBottom: '4px',
          }}
        >
          <span style={{ color: '#6fd943', fontWeight: 'bold' }}>التاريخ:</span> {deduction.date}
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontFamily: 'Cairo, sans-serif',
            color: darkTheme.palette.text.primary,
            marginBottom: '4px',
          }}
        >
          <span style={{ color: '#6fd943', fontWeight: 'bold' }}>الوقت:</span> {deduction.time}
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontFamily: 'Cairo, sans-serif',
            color: darkTheme.palette.text.primary,
            marginBottom: '4px',
          }}
        >
          <span style={{ color: '#6fd943', fontWeight: 'bold' }}>الخصم:</span> {deduction.deduction} جنيه
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontFamily: 'Cairo, sans-serif',
            color: darkTheme.palette.text.primary,
          }}
        >
          <span style={{ color: '#6fd943', fontWeight: 'bold' }}>السبب:</span> {deduction.reason}
        </Typography>
        <Box
          sx={{
            width: '100%',
            height: '1px',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            marginTop: '8px',
          }}
        />
      </Box>
    ))}
    {/* Display the total sum of deductions */}
    <Typography
      variant="h6"
      style={{
        marginTop: '16px',
        color: '#6fd943',
        fontFamily: 'Cairo, sans-serif',
        fontWeight: 'bold',
        textAlign: 'center',
      }}
    >
      إجمالي الخصومات: {totalDeductionsSum.toFixed(2)} جنيه
    </Typography>
  </Box>
)}
  </Paper>
</Grid>

        </Grid>

        <Grid container spacing={3} sx={{ marginTop: '16px' }}>
  <Grid item xs={12} md={6}>
    <Paper style={cardStyle}>
      <Typography variant="h6" style={{ fontFamily: 'Cairo, sans-serif', color: '#6fd943', marginBottom: '8px' }}>
        الإجازات
      </Typography>
      <Box sx={{ width: '100%', height: '300px' }}>
        <Bar
          data={leaveChartData}
          options={{
            plugins: {
              legend: { display: true, labels: { color: '#FFFFFF' } },
            },
            scales: {
              x: { ticks: { color: '#FFFFFF' } },
              y: { ticks: { color: '#FFFFFF' } },
            },
            maintainAspectRatio: false,
          }}
        />
      </Box>
    </Paper>
  </Grid>

  <Grid item xs={12} md={6}>
    <Paper style={cardStyle}>
      <Typography variant="h6" style={{ fontFamily: 'Cairo, sans-serif', color: '#6fd943', marginBottom: '8px' }}>
        الأذونات
      </Typography>
      <Box sx={{ width: '100%', height: '300px' }}>
        <Bar
          data={permissionChartData}
          options={{
            plugins: {
              legend: { display: true, labels: { color: '#FFFFFF' } },
            },
            scales: {
              x: { ticks: { color: '#FFFFFF' } },
              y: { ticks: { color: '#FFFFFF' } },
            },
            maintainAspectRatio: false,
          }}
        />
      </Box>
    </Paper>
  </Grid>
</Grid>;

        {/* Personal and Company Information */}
        <Grid container spacing={3} sx={{ marginTop: '16px' }}>
          <Grid item xs={12} md={4}>
            <Paper style={cardStyle}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Avatar
                  alt={employee.name}
                  src={`https://backendforerpsystem.zbooma.com/${employee.photo}`}
                  style={{ width: '80px', height: '80px', marginBottom: '8px' }}
                />
                <Typography
                  variant="h6"
                  style={{ marginBottom: '16px', fontFamily: 'Cairo, sans-serif', textAlign: 'center', color: '#6fd943' }}
                >
                  {employee.name}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', textAlign: 'center' }}
                >
                  <span style={{ color: '#6fd943', fontWeight: 'bold' }}>المنصب:</span> {employee.position}
                </Typography>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={8}>
            <Paper style={cardStyle}>
              <Typography variant="h6" style={{ marginBottom: '16px', fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>
                تفاصيل الشركة
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>فرع:</span> {employee.branch}
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>الإدارة:</span> {employee.department}
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>القسم:</span> {employee.department2}
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>تاريخ الانضمام:</span> {employee.joining_date}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* Personal Details */}
        <Grid container spacing={3} sx={{ marginTop: '16px' }}>
          <Grid item xs={12} md={6}>
            <Paper style={cardStyle}>
              <Typography variant="h6" style={{ fontFamily: 'Cairo, sans-serif', color: '#6fd943', marginBottom: '16px' }}>
                تفاصيل شخصية
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>كود الموظف:</span> {employee.employee_code}
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>الاسم:</span> {employee.name}
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>تاريخ الميلاد:</span> {employee.birthdate}
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>البريد الإلكتروني:</span> {employee.email}
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>العنوان:</span> {employee.address}
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>الجنس:</span> {employee.gender}
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>الهاتف:</span> {employee.phone}
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>يوم الاجازه:</span> {employee.blocked_day_name }
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>المرتب:</span> {employee.salary} جنيه
              </Typography>
            </Paper>
          </Grid>

          {/* Bank Account Details */}
          <Grid item xs={12} md={6}>
            <Paper style={cardStyle}>
              <Typography variant="h6" style={{ fontFamily: 'Cairo, sans-serif', color: '#6fd943', marginBottom: '16px' }}>
                تفاصيل الحساب المصرفي
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>رقم الحساب:</span> {employee.bank_account_number}
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>اسم صاحب الحساب:</span> {employee.bank_account_name}
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>اسم البنك:</span> {employee.bank_name}
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>كود تعريف البنك:</span> {employee.bank_id_code}
              </Typography>
              <Typography variant="body1" style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}>
                <span style={{ color: '#6fd943', fontWeight: 'bold' }}>كود دافع الضريبة:</span> {employee.taxpayer_code}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* Documents and Certificates */}
        <Grid container spacing={3} sx={{ marginTop: '16px' }}>
          <Grid item xs={12}>
            <Paper style={cardStyle}>
              <Typography variant="h6" style={{ marginBottom: '8px', fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>
                الوثائق والشهادات
              </Typography>
              {Array.isArray(employee.certificates) && employee.certificates.length > 0 ? (
                employee.certificates.map((certificate, index) => (
                  <Box key={index} display="flex" alignItems="center" justifyContent="flex-start" mt={2}>
                    {canPreviewFile(certificate) && (
                      <Button
                        onClick={() => handleOpenDialog(certificate)}
                        variant="contained"
                        color="primary"
                        startIcon={<VisibilityIcon style={{ marginLeft: '8px' }} />}
                        style={{ marginRight: '8px', backgroundColor: '#6fd943', fontFamily: 'Cairo' }}
                      >
                        معاينة
                      </Button>
                    )}
                    <IconButton
                      href={`https://backendforerpsystem.zbooma.com/${certificate}`}
                      download
                      style={{ color: '#1e88e5', padding: 0 }}
                      aria-label="download certificate"
                    >
                      <Description />
                    </IconButton>
                    <Typography
                      variant="body1"
                      style={{ fontFamily: 'Cairo, sans-serif', marginLeft: '8px' }}
                    >
                      <a
                        href={`https://backendforerpsystem.zbooma.com/${certificate}`}
                        download
                        style={{ color: '#1e88e5', textDecoration: 'none' }}
                      >
                        تحميل الشهادة
                      </a>
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography
                  variant="body1"
                  style={{ color: darkTheme.palette.text.secondary, fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
                >
                  لا توجد وثائق أو شهادات مسجلة.
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>

        {/* Dialog for certificate preview */}
        <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
          <DialogTitle style={{ fontFamily: 'Cairo, sans-serif', textAlign: 'center', color: darkTheme.palette.text.primary }}>
            معاينة الشهادة
          </DialogTitle>
          <DialogContent>
            {currentCertificate && currentCertificate.endsWith('.pdf') && (
              <iframe
                src={`https://backendforerpsystem.zbooma.com/${currentCertificate}`}
                style={{ width: '100%', height: '500px' }}
                title="Certificate Preview"
              />
            )}
            {currentCertificate && (currentCertificate.endsWith('.docx') || currentCertificate.endsWith('.ppt') || currentCertificate.endsWith('.pptx')) && (
              <iframe
                src={`https://docs.google.com/viewer?url=https://backendforerpsystem.zbooma.com/${currentCertificate}&embedded=true`}
                style={{ width: '100%', height: '500px' }}
                title="Certificate Preview"
              />
            )}
            {currentCertificate &&
              !currentCertificate.endsWith('.pdf') &&
              !currentCertificate.endsWith('.docx') &&
              !currentCertificate.endsWith('.ppt') &&
              !currentCertificate.endsWith('.pptx') && (
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: 'Cairo, sans-serif',
                    textAlign: 'center',
                    marginTop: '16px',
                    color: darkTheme.palette.text.secondary,
                  }}
                >
                  المعاينة غير مدعومة لهذا النوع من الملفات. يمكنك تنزيل الملف لمشاهدته.
                </Typography>
              )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDialog}
              style={{ fontFamily: 'Cairo, sans-serif', color: darkTheme.palette.text.primary }}
            >
              إغلاق
            </Button>
          </DialogActions>
        </Dialog>

        {/* Automatically open dialog */}
        {/* <AutoOpenDialog open={openDialog} handleClose={handleCloseDialog} username={username} /> */}
      </Box>
    </ThemeProvider>
  );
};

export default Profile;
